import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ImageType } from "../constants/Image";
import TitleAndImageWrapper from "../components/TitleAndImageLayout";
import { ErrorV1 } from "../contracts/api";
import { useEffect } from "react";
import { trackEvent } from "../utils/analytics";

function Error() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let title = `Uh oh, an error occurred`
  let subtitle: string | undefined = `We're sorry, but we experienced an unexpected error. Please try again or contact our support team for assistance if the problem persists.`;
  let children = <Box
    alignItems={'center'}
    mb={4}>
    <Button
      variant="contained"
      type="submit"
      color="primary"
      sx={{ mt: 2, marginX: 'auto', mr: 2 }}
      onClick={() => navigate('/')}
    >
      Go Home
    </Button>
  </Box>

  let loginErrorMobileChildren = <Box>
    <Typography style={{textAlign: "center"}}>We’re sorry for the inconvenience and are working quickly to resolve the issue.</Typography>
    <Typography style={{textAlign: "center"}}>While we sort this out, please follow these steps to troubleshoot:</Typography>
        <Typography style={{textAlign: "center", marginTop: 32}} variant="h6">Log out and log in again:</Typography>
        <Typography style={{textAlign: "center", marginTop: 16}} variant="h6">
            <a href="/logout" style={{color: "inherit"}}>Click here to log out</a>
        </Typography>
    <Typography style={{textAlign: "center", marginTop: 32}}>Thank you for your patience and understanding. If you continue to experience issues, please contact our support team for further assistance.</Typography>
  </Box>

  let loginErrorDesktopChildren = <Box>
    <Typography style={{textAlign: "center"}}>We’re sorry for the inconvenience and are working quickly to resolve the issue.</Typography>
    <Typography style={{textAlign: "center"}}>While we sort this out, please follow these steps to troubleshoot:</Typography>
    <Stack direction={'row'} justifyContent="center">
        <Typography variant="h6">
        <ol>
            <li>
                Perform a hard refresh:
                    <ul style={{'listStyleType': 'none', marginTop: 16}}>
                        <li>
                            <Typography><b>Windows:</b> Control + F5</Typography>
                        </li>
                        <li>
                            <Typography><b>Mac:</b> Command + Shift + R</Typography>
                        </li>
                    </ul>
                </li>
                <li style={{marginTop: 32}}>
                    Log out and log in again:
                    <ul style={{'listStyleType': 'none', marginTop: 16}}>
                        <li>
                            <Typography><a href="/logout" style={{color: "inherit"}}>Click here to log out</a></Typography>
                        </li>
                    </ul>
                </li>
            </ol>
        </Typography>
    </Stack>
    <Typography style={{textAlign: "center"}}>Thank you for your patience and understanding. If you continue to experience issues, please contact our support team for further assistance.</Typography>
  </Box>

  useEffect(() => {
    document.title = "Error | TheGuarantors"
    trackEvent('Error Page Loaded', {
      referrer: window.location.pathname,
    });
  },[]);

  if (!!state) {
    let err = (state as ErrorV1)
    switch (err.message) {
      case "SIGNED_LINK_EXPIRED":
        console.debug(err.message)
        title = "Your link has expired"
        subtitle = "The link you recently requested has expired. Please ensure you are using the correct link or request a new one."
        break;
      case "SET_PASSWORD_SIGNED_LINK_USED":
        console.debug(err.message)
        title = "You've already set a password"
        subtitle = "You can log in or reset your password by going to the Sign-In page."
        break;
      case "SET_PASSWORD_SIGNED_LINK_EXPIRED":
        console.debug(err.message)
        title = "Your link has expired"
        subtitle = "You can still set a password by clicking 'Forgot Password' on the Sign-In page."
        break;
      case "FAILED_TO_GET_ACCESS_TOKEN":
        console.error(err.message);
        window.location.href = '/logout';
        break;
      case "FAILED_LOCALSTORAGE":
      case "FAILED_TO_AUTHENTICATE":
      case "FAILED_TO_GET_TOKEN":
      case "FAILED_TO_EXCHANGE_CODE":
        console.error(err.message);
        title = "Uh oh, a login error occurred"
        subtitle = ''
        children = isMobile ? loginErrorMobileChildren : loginErrorDesktopChildren
        break;
      case "SIGNED_LINK_INVALID":
        console.debug(err.message)
        title = "Your link is invalid"
        subtitle = "The link you received appears to be invalid. Please ensure you are using the correct link or request a new one."
        break;
      default:
        console.error(JSON.stringify(state, null, 2))
    }

    if (err.errorCode !== undefined) {
      console.error(`ErrorCode: ${err.errorCode}`)
      subtitle += ` Error ${err.errorCode}.`
    }
  }

  return (
    <TitleAndImageWrapper
      title={title}
      subtitle={subtitle}
      image={ImageType.STAIRS}
      children={children} />
  );
}

export default Error;
