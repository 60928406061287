import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { logoutAsync } from "../../lib/api";
import { SetCookieRaw } from "../../lib/helpers";
import Loader from "../../components/Loader";

export default function HandleLogout() {
  const { logout, isLoading } = useAuth0();

  useEffect(() => {
    console.log("auth0 debug: logout useEffect run", {isLoading})
    if (isLoading) return;

    const callLogout = async () => {
      try {
        let refresh_token = localStorage.getItem("refresh_token") ?? undefined;
        localStorage.clear();
        SetCookieRaw("auth_tokens", "", "/");
        await logoutAsync({ refresh_token: refresh_token });
      } catch (error) {
        console.error(error);
      }

      logout();
    };

    callLogout();
  }, [logout, isLoading]);

  return <Loader />;
}
